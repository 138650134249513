import React from "react";
import "./legal.scss";
import Layout from "../components/Layout";

const LegalPage = () => {
  return (
    <Layout
      onScrollToHero={() => (window.location.href = "https://zen-code.ro")}
      onScrollToWork={() => {}}
      onScrollToAbout={() => {}}
      onScrollToService={() => {}}
      onScrollToContact={() => {}}
      pageTitle="Zen code - Legal"
      showNav={false}
    >
      <div className="legal">
        <h2 id="Terms">Imprint</h2>
        <h3>Zen code software enterprise SRL</h3>
        <h3>I C Bratianu 100, bloc SR2, ap 26, Constanta, Romania</h3>
        <h3>Software</h3>

        <h3>+40 720 205 804</h3>
        <h3>support@zen-code.ro</h3>

        <h3>UID: ROONRC.J13/2432/2022</h3>
        <h3>Company registration: 46452896</h3>
        <h3>
          Court of registry: Oficiul registrului comertului de pe langa
          tribunalul constanta
        </h3>
      </div>
    </Layout>
  );
};

export default LegalPage;
